<template>
  <topBanner /> 
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sigma_about mt-5 mt-lg-0">
            <h5 class="title">{{ privacypolicyData.section1.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section1.desc" :key="item.id">{{ item.row}}</p> 
            </div>

            <h5 class="title mt-5">{{ privacypolicyData.section2.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section2.desc" :key="item.id">{{ item.row}}</p>
            </div>

            <h5 class="title mt-5">{{ privacypolicyData.section3.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section3.desc" :key="item.id">{{ item.row}}</p>
            </div>

            <h5 class="title mt-5">{{ privacypolicyData.section4.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section4.desc" :key="item.id">{{ item.row}}</p>  
            </div>

            <h5 class="title mt-5">{{ privacypolicyData.section5.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section5.desc" :key="item.id">{{ item.row}}</p> 
            </div>

            <h5 class="title mt-5">{{ privacypolicyData.section6.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section6.desc" :key="item.id">{{ item.row}}</p> 
            </div>

            <h5 class="title mt-5">{{ privacypolicyData.section7.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section7.desc" :key="item.id">
                {{ item.row}}
                <ul class="data">
                  <li v-for="item2 in item.reasons" :key="item2.id">{{ item2.row }}</li> 
                </ul>
              </p> 
            </div>

            <h5 class="title mt-5">{{ privacypolicyData.section8.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in privacypolicyData.section8.desc" :key="item.id">{{ item.row}}</p>  
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div> 
</template>

<script>
import data from '../Data/data.json'
import topBanner from "../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      privacypolicyData: ''
    };
  },
  created(){
    this.privacypolicyData = data.privacy_policy
  }
};
</script>

<style>
.data{
    margin-left: 30px;
}
.data li{
    list-style: circle;
}
</style>